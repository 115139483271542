import React from 'react';

const Footer = () => {
  return (
    <footer className="  bg-[#F4BFAE] text-white p-4 mt-48">
      <div className="container mx-auto flex justify-between text-black">
        <p>&copy;{` ${Date().split(' ')[3]} `}All Rights Reserved</p>
        <a href='http://www.freevisitorcounters.com'>Visitor Counts</a> <script type='text/javascript' src='https://www.freevisitorcounters.com/auth.php?id=82adacc59baf65c79123330a167159b3d3118509'></script>
        <script type="text/javascript" src="https://www.freevisitorcounters.com/en/home/counter/1241128/t/5"></script>
      </div>
    </footer>
  );
};

export default Footer;
