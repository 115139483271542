import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

function Workshop2() {
  const data = {
    events: [
      {
        "time_BST": "09.00 - 10.00",
        "time_IST": "13.30 - 14.30",
        "event": "Guest Speaker",
        "resource_person": "Dr. Dirk Honecker",
        "topic": "Small Angle Neutron Scattering characterization"
    },
    {
        "time_BST": "10.00 - 11.00",
        "time_IST": "14.30 - 15.30",
        "event": "Guest Speaker",
        "resource_person": "Dr. Yisong Han",
        "topic": "Transmission Electron Microscopy"
    },
    {
        "time_BST": "11.00 - 12.00",
        "time_IST": "15.30 - 16.30",
        "event": "Guest Speaker",
        "resource_person": "Dr. Matheus Porto",
        "topic": "Thermography for Materials Characterization: Challenges and Future Perspectives"
    },
    {
        "date": "07-10-2024",
        "day": "Monday",
        "time_BST": "12.00 - 13.00",
        "time_IST": "16.30 - 17.30",
        "event": "Guest Speaker",
        "resource_person": "Dr. Pamu",
        "topic": "An overview of materials (bulk and thin films): A case study of dielectric measurements."
    },
    {
        "time_BST": "13.00 - 14.00",
        "time_IST": "17.30 - 18.30",
        "event": "Break",
        "resource_person": "Break"
    },
    {
        "time_BST": "14.00 - 16.00",
        "time_IST": "18.30 - 20.30",
        "event": "Hands On Training",
        "resource_person": "Hands on training"
        
    }
    ],
  };
  const members = [
    "Dr. Jayesh Shanthi Bhavan (Lead, UK), Coventry University",
    "Dr. P S Robi (Lead, India), IT Guwahati",
    "Mr. Gojen Doley (Industry Lead), NRL",
    "Dr. Perumal Alagarsamy, IIT Guwahati",
    "Dr. R. Prasanna Venkatesh, IIT Guwahati",
    "Dr. Mahshid Yazdi Far, Coventry University",
    "Mr. Prangan Bhattacharyya, NRL",
    "Mr. Javed Mahammed, NRL",
    "Mr. Deepjyoti Deka, NRL",
    "Dr. Gokulnath Kadavath, CET",
    "Mr. Sreekumar CR, GECT",
  ];
  return (
    <div>
      <Navbar />
      <div className=" md:my-[7%] md:mx-[5%] my-[20%] mx-4">
        <div className="   font-bold text-3xl mb-8  ">
          One-Day Workshop II on “Material Characterisation and Analysis”
        </div>

        <div className=" md:flex md:flex-row flex gap-7  items-center  md:gap-2 flex-col">
          <div className=" border p-4 py-10 whitespace-nowrap bg-red-50 flex flex-col items-center justify-center border-black">
            <div className="font-bold  py-1 tab_active px-5 mb-5 flex  ">
              Event Details
            </div>
            <div className="flex gap-3 my-1">
              <div className=" font-bold">Date: </div>
              <div>October 07th, 2024</div>
            </div>
            <div className="flex gap-3 my-1">
              <div className=" font-bold">Location:</div> Coventry University, UK
            </div>
            <div className="flex gap-3 my-1">
              <div className=" font-bold">Modes:</div>
              <div> Online and Offline</div>
            </div>
          </div>
          <div className="  text-[18px]  md:px-10 md:text-2xl md:text-left px-9 text-center font-extralight">
            This workshop will be held at Coventry University, accommodating
            both Indian and UK participants by adjusting the schedule
            accordingly. Participants are welcome to attend the workshop in
            person (Room No. WM105) or join online if unable to travel. Don't
            miss this opportunity to enhance your skills and knowledge in
            material Characterization and analysis!
          </div>
        </div>
        <div className=" flex w-full overflow-scroll no-scrollbar">
          <div className="  w-[720px]   md:w-full flex flex-col my-10 text-center border-black border gap-">
            <div className=" md:w-full flex  w-[720px]">
              <div className=" w-1/6 border-r   border-b border-black">
                Date
              </div>
              <div className=" w-1/6 border-r border-b border-black">
                Time (UK)
              </div>
              <div className=" w-1/6  border-r  border-b border-black">
                Time (India)
              </div>
              <div className=" w-1/3 border-r border-b border-black">Speaker</div>
              <div className=" w-1/3 border-b  border-black">
                Topic
              </div>
            </div>

            {data.events.map((event, index) => (
              <div className=" flex">
                <div className= {`  w-1/6  border-r border-black `}>
                  {event.date && event.date}
                  <br />
                  {event.day && event.day}
                </div>
                <div className={` ${(index===4||index===5)&&"font-extrabold"}  w-1/6 py-4 border-r border-b  border-black }`}>
                  {event["time_BST"]}
                </div>
                <div className={` ${(index===4||index===5)&&"font-extrabold"}  w-1/6 py-4 border-r border-b border-black `}>
                  {event["time_IST"]}
                </div>
                <div className={`" ${(index===4||index===5)&&"font-extrabold  border-r-0 "}  w-1/3 py-4 border-r border-b border-black "`}>
                  {event["resource_person"]}
                </div>
                <div className={`" ${index===4&&"font-extrabold"}  w-1/3 py-4 border-b border-black "`}>
                  {event["topic"]}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className=" font-bold text-3xl mb-8">Workshop Committee</div>
        <div className=" flex flex-col">
          {members.map((member, index) => (
            <div className="  text-lg font-thin">
              <span className=" font-bold pr-2">{index + 1}.</span> {member}
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Workshop2;
