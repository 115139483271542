import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import { Link } from 'react-router-dom'

function Teams() {
  const teamsData=[
    {
      pf:" /teams/prof.robi.jpg",
      name:"Prof. P.S. Robi(Lead India)",
      College:"IIT Guwahati, India",
      website:"https://iitg.ac.in/mech/faculty/psr/"
    },
    {
      pf:" /teams/dr.jayesh.jpg",
      name:"Dr.Jayesh Shanthi Bhavan(Lead UK)",
      College:"Coventry University,UK",
      website:"https://pureportal.coventry.ac.uk/en/persons/jayesh-shanthi-bhavan"
    },
    {
      pf:" /teams/perumal.jpg",
      name:"Prof. Perumal Alagarsamy",
      College:"IIT Guwahati, India",
      website:"https://www.iitg.ac.in/perumal/"
    },
    {
      pf:" /teams/venkatesh.jpg",
      name:"Dr. R. Prasanna Venkatesh",
      College:"IIT Guwahati, India",
      website:"https://www.iitg.ac.in/chemeng/faculty_profile.php?name=rpv"
    },
    {
      pf:" /teams/dr.mahshid.jpg",
      name:"Dr.Mahshid Yazdi Far",
      College:"Coventry University, UK",
      website:"https://pureportal.coventry.ac.uk/en/persons/mahshid-yazdi-far"
    },
    {
      pf:" /teams/singha.jpg",
      name:"Pranta Pratim Singha",
      College:"NRL",
      website:"https://www.linkedin.com/in/pranta-pratim-singha-6606a912"
    },
    {
      pf:" /teams/deka.jpg",
      name:"Deepjyoti Deka",
      College:"NRL",
      website:"https://www.linkedin.com/in/deepjyoti-deka-7b519314"
    },
    {
      pf:" /teams/default.png",
      name:"Gojen Doley",
      College:"NRL",
      website:"https://www.linkedin.com/in/gojen-doley-49573915"
    },


  
 

  ]
  return (
    <div>
    <Navbar />
    <div className=" md:my-[7%] md:mx-[5%] my-[20%] mx-4">
      <div className="   font-bold text-3xl mb-8  ">Meet Our Team</div>
      <div className=' flex flex-wrap  justify-center gap-12'>
 {  teamsData.map((data,index)=> <div className='  border-2 border-black  rounded-t-md  h-[350px] md:h-[250px] md:w-[13%] w-[250px] flex flex-col  bg-cover  items-center justify-center  relative samplebg '>
      <img src={data.pf} alt='pf' className='  absolute  -z-10'/>
      <div className='absolute  bg-black bg-opacity-30 bg-gradient-to-t from-[#6a6868]  to-transparent w-full bottom-0 px-2'>
        <div className=' text-white text-base font-medium   text-end '>
          {data.name}
        </div>
        <div className=' text-[#FD6039] text-xs font-extralight  text-end  whitespace-nowrap'>
       {data.College}
        </div></div>
        <Link to={data.website} target='blank' className=' text-xs bottom-0 absolute translate-y-[40px] font-semibold w-full gap-3 bg-[#ff8465] rounded-b-md justify-center border-2 flex items-center py-2  border-black cursor-pointer'>
        <img src='assets/element22.svg' alt='link' width={"20px"}/>   Personal Website
        </Link>
    </div> 
    )} 
      </div>
    </div>
    <Footer/>
  </div>
  )
}

export default Teams
