import React, {  useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import FAQ from "../components/faq";
import { Link } from "react-router-dom";
import Carousel from "../components/carousel";
import Popup from "../components/workshop_popup";

function Home() {
  const ApplyNowURL =
    "https://forms.office.com/Pages/ResponsePage.aspx?id=mqsYS2U3vkqsfA4NOYr9T1NaKJI7205JtHEbDYwFrvZUM0JGMUFISE9BVVgzSDhGSVZVN0JWQzNEMS4u";
  const [showPopup, setShowPopup] = useState(false||localStorage.getItem('revisited2'));
  return (
    <div className=" h-screen">
      <Navbar />
      <div className=" flex justify-center items-center gap-2 md:gap-8 md:mx-[10%] mx-[3%] md:mt-[8%] mt-[25%] mb-10 ">
        <img
          alt=" iitg logo"
          className=" md:w-36 w-16 "
          src="/assets/iitg.gif"
        />
        <img alt=" line " className=" h-16 md:h-full" src="/assets/line.svg" />
        <img
          alt=" cov_university"
          className=" md:w-44 w-28"
          src="/assets/cov_univer.png"
        />
        <img alt=" line " className="  h-16 md:h-full" src="/assets/line.svg" />
        <img
          alt=" nrf logo"
          className=" md:w-20 w-10"
          src="/assets/nrl-logo.jpg"
        />
        <img alt=" line " className="h-16 md:h-full" src="/assets/line.svg" />
        <img
          alt=" britishcoucil logo"
          className=" md:w-44 w-28"
          src="/assets/BritishCouncil.png"
        />
      </div>

      <div className="flex flex-col items-center ">
        <div className="md:mx-[10%] mx-[3%]  p-5 flex flex-col justify-between border-4 border-[#303F47]  rounded-[26px]   ">
          <div className=" flex flex-col gap-4">
            <div className="  text-4xl md:text-4xl  mt-5 md:px-10  font-bold ">
              Bridging the Gap:
              <span className=" text-3xl  md:text-4xl">
                {" "}
                Developing an Employable
                Skilled Workforce in Material
                Characterisation, Testing and Analysis
              </span>
            </div>
            <div className=" font-extralight text-xl  mt-1 md:px-10  ">
              Curriculum developed by{" "}
              <a href="https://www.coventry.ac.uk/"  target="blank"  className=" font-light mx-1 underline cursor-pointer">
                Coventry University
              </a>{" "}
              (UK),
              <a
                href={'https://www.iitg.ac.in/'}
                target="blank" 
                className=" mx-1 font-light underline cursor-pointer"
              >
                IIT Guwahati
              </a>
              (India) and{" "}
              <a href="https://www.nrl.co.in/" target="blank" className="  font-light mx-1 underline cursor-pointer">
                Numaligarh Refinery Limited
              </a>{" "}
              (Industry Partner, India) under the
                British Council's
              funding (Going Global Partnership Grant)
            </div>
            <div className="  font-extrabold md:px-10  text-xl text-[#CC523F]   my-2 mt-1 ">
              Advance Your Career with Cutting-Edge
          Materials Science Training
            </div>
            <div className=" text-[18px] pr-10 md:px-10 md:text-2xl font-extralight">
              Join our comprehensive training program on materials science,
              testing,and analysis. From theoretical foundations to hands-on
              laboratory experience, enhance yourskills with industry-standard
              practices and tools.
            </div>
          </div>

          <div className="flex gap-2 px-10 translate-y-10 mt-12 ">
            <a
              href={ApplyNowURL}
              className="bg-[#CC523F] cursor-pointer rounded border-black border-2  text-white p-2 px-4 md:px-7 "
            >
              Apply Now
            </a>
            <Link
              to={"/learning-outcome"}
              className="bg-[#CC523F] rounded border-black cursor-pointer border-2 text-white p-2 md:px-5 px-2 flex gap-2 items-center justify-center"
            >
              Learn More
              <img
                alt="arrow"
                src="/assets/arrow.svg"
                className="hover:rotate-[47deg] transition-all"
                width="15px"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className=" my-10">
        <FAQ />
      </div>
      <div className=" md:px-[10%] px-4 my-28">
        <Carousel />
      </div>
    {
      !showPopup && (<Popup onClose={() => {setShowPopup(true);localStorage.setItem('revisited2',true)}} />)

    }


      <Footer />
    </div>
  );
}

export default Home;
